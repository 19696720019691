<template>
  <div id="mbti-result" class="section">
    <div class="jumbotron text-right">
      <div class="container">
        <h2>Tipe kepribadianmu adalah</h2>
        <h1>ISTJ</h1>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-lg-7">
          <p>
            Seseorang yang berkepribadian ISTJ sering disebut The Inspector atau pengawas.  Ada sangat banyak pribadi yang termasuk ISTJ yakni 11-14% populasi di dunia. Dimana menurut dimensinya ISTJ berarti Introversion atau seseorang yang tidak suka akan hubungan dan dunia luar. Sensing yaitu mereka yang mengenal hal-hal secara konkret atau nyata Thinking merupakan dimensi dasar mereka dimana mereka suka menilai dan berpikir sebelum bertindak dan sangat objektif. Judging merupakan mereka yang merencanakan berbagai hal sebelum bertindak. 
          </p>
          <p>
            Ciri kepribadian ISTJ adalah:
            <ul>
              <li> Menjunjung nilai tradisi dan juga sosok yang mampu bekerja keras dan menyelesaikan waktu tugas dengan tepat, mereka sering disebut berkepribadian ganda meskipun sebenarnya tidak.</li>
              <li> Menyukai teori abstrak dan melihat aplikasi praktis.</li>
              <li> Pemimpin alami. </li>
              <li> Suka bekerja sendiri dan baik juga didalam tim.</li>
              <li> Stabil, praktis, dan berorientasi pada keluarga.</li>
            </ul>
          </p>
          <p>Saran dan pekerjaan yang cocok:
            <ul>
              <li>
                Belajar memahami perasaan orang lain dan kontrol keinginan anda untuk menguasai orang lain secara berlebih.
              </li>
              <li>
                Lihat lebih banyak sisi positif dibandingkan sisi negative seseorang.
              </li>
              <li>
                Terbukalah pada perubahan.
              </li>
              <li>
                Pekerjaan yang cocok adalah akuntan, dokter, pengacara, dokter gigi dan juga sistem analis.
              </li>
            </ul>
          </p>
          <p>
            Asmara untuk ISTJ adalah mereka yang memiliki kepribadian ESFP atau ESTP yang mungkin lebih bisa mengimbangi pribadi ISTJ.
          </p>
        </div>
        <div class="col-lg-5">
          <img src="../../../assets/images/mbti/ISTJ.png" alt="" class="illustration">
          <div class="media">
            <img src="../../../assets/images/mbti/Introversion.png" class="mr-3" alt="">
            <div class="media-body">
              <h5 class="introversion">Introversion</h5>
              <p>cenderung pendiam dan suka menyendiri</p>
            </div>
          </div>
          <div class="media">
            <img src="../../../assets/images/mbti/Sensing.png" class="mr-3" alt="">
            <div class="media-body">
              <h5 class="sensing">Sensing</h5>
              <p>lebih fokus terhadap gambaran masa depan</p>
            </div>
          </div>
          <div class="media">
            <img src="../../../assets/images/mbti/Thinking.png" class="mr-3" alt="">
            <div class="media-body">
              <h5 class="thinking">Thinking</h5>
              <p>membuat keputusan berdasarkan logika</p>
            </div>
          </div>
          <div class="media">
            <img src="../../../assets/images/mbti/Judgement.png" class="mr-3" alt="">
            <div class="media-body">
              <h5 class="judgement">Judgement</h5>
              <p>cenderung membiarkan pilihan tetap terbuka</p>
            </div>
          </div>
        </div>
      </div>
      <ShareButton :result="result" />
    </div>
  </div>
</template>

<script>
export default {
  name: "ISTJ",
  components: {
    ShareButton: () => import('@/components/ShareButton'),
  },
  data() {
    return {
      result: {
        name: 'ISTJ',
        slug: 'istj'
      }
    }
  }
};
</script>

<style scoped>

</style>
